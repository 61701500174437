import type {
  IName,
  Address
} from "@/models/commercialData/lexisnexis/KybReport";
import { formatAddress } from "@/helpers/formatting";
import { STATE_NAMES } from "@/helpers/constants";
import type {
  NameParts,
  RecordDate
} from "@/models/commercialData/lexisnexis/LexisNexisKycSearch";

export const getSectionName = (
  section: string,
  index: number,
  subindex?: number
) => {
  if (subindex) {
    return `business_${index}_${section}_${subindex}`;
  }
  return `business_${index}_${section}`;
};

export const getName = ({ Prefix, First, Middle, Last }: IName) => {
  return [Prefix, First, Middle, Last]
    .filter((namePart) => !!namePart)
    .join(" ");
};

export const getStateName = (state: string) => {
  return STATE_NAMES[state as keyof typeof STATE_NAMES] || "";
};

export const cleanAndFormatAddress = (address: Address | undefined) => {
  if (!address) {
    return "-";
  }
  const address_line = [
    address.StreetNumber || "",
    address.StreetName || "",
    address.StreetSuffix || ""
  ]
    .filter(Boolean)
    .join(" ");
  const zip = [address.Zip5 || "", address.Zip4 || ""]
    .filter(Boolean)
    .join("-");
  return formatAddress({
    address_line,
    address_line2: null,
    city: address.City,
    state: address.State,
    zip
  });
};

export const joinNameParts = (nameParts: NameParts | undefined) =>
  nameParts && Object.values(nameParts || {}).length
    ? [nameParts.First, nameParts.Middle, nameParts.Last, nameParts.Prefix]
        .filter(Boolean)
        .join(" ")
    : "-";

export const validateNum = (param: number | undefined) => {
  if (typeof param === "undefined") {
    return "-";
  }
  return param || 0;
};

export const getDate = (dateParts: RecordDate | undefined) =>
  dateParts && Object.values(dateParts || {}).length
    ? [dateParts.Month, dateParts.Day, dateParts.Year].filter(Boolean).join("/")
    : "-";

export const getTime = (time: string | undefined) => {
  if (!time) {
    return "-";
  }

  if (time.length === 2) {
    return `${time}:00`;
  }

  if (time.length === 3) {
    return `0${time.slice(0, 1)}:${time.slice(1)}`;
  }

  if (time.length === 4) {
    return `${time.slice(0, 2)}:${time.slice(2)}`;
  }

  return time;
};
