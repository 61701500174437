<template>
  <toggle-section
    chevron-position="right"
    section="attorneys"
    :is-open="isSectionOpen['attorneys'] ?? true"
    :has-padding="false"
    @toggle="toggle"
  >
    <template #titleSection>
      <div class="flex flex-grow items-center space-x-2">
        <lf-h3>{{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.ATTORNEYS") }}</lf-h3>
        <lf-pill :color-index="15">
          {{ attorneys.length }}
        </lf-pill>
      </div>
    </template>
    <template #advanced>
      <icon-base
        class="mx-4"
        :icon="!allSectionsOpen ? 'expandall' : 'collapseall'"
        @click.stop="allSectionsOpen = !allSectionsOpen"
      />
    </template>
    <template #content>
      <div class="divide-y">
        <div class="px-6 py-4 grid grid-cols-4 space-x-2">
          <span class="text-label">
            {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.COMPANY_NAME") }}
          </span>
          <span class="text-label">
            {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.EMAIL") }}
          </span>
          <span class="text-label">
            {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.ADDRESS") }}
          </span>
          <span class="text-label">
            {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.PHONES") }}
          </span>
        </div>
        <div v-for="(attorney, i) in attorneys" :key="i">
          <div
            class="py-4 px-6 grid grid-cols-4 gap-x-2 items-start cursor-pointer"
            @click="handleAttorneyClick(i)"
          >
            <div class="flex flex-col space-y-1">
              <span
                v-for="name in attorney.Names?.Name"
                class="text-headline"
                :key="name.CompanyName"
              >
                {{ validateString(name.CompanyName) }}
              </span>
            </div>
            <div class="flex flex-col space-y-1">
              <span
                v-for="email in attorney.Emails?.Email"
                class="text-headline"
                :key="email"
              >
                {{ validateString(email) }}
              </span>
            </div>
            <div class="flex flex-col space-y-2">
              <div
                v-for="address in attorney.Addresses?.Address"
                class="text-headline flex flex-col"
                :key="cleanAndFormatAddress(address)"
              >
                <span class="text-headline">
                  {{ cleanAndFormatAddress(address) }}
                </span>
                <span class="text-label">
                  {{
                    validateString(
                      address.OrigStreetAddress1 || address.OrigStreetAddress2
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="flex items-center justify-between gap-x-2">
              <div class="flex flex-col space-y-2">
                <div
                  v-for="phone in attorney.Phones?.Phone"
                  class="text-headline flex flex-col"
                  :key="phone.Phone10"
                >
                  <div class="flex items-center space-x-2">
                    <icon-base
                      icon="telephone"
                      :icon-color="UTIL_COLORS.DEFAULT"
                    />
                    <span class="text-headline">
                      {{ validateString(phone.Phone10) }}
                    </span>
                  </div>
                  <div class="flex items-center space-x-2">
                    <icon-base icon="telefax" />
                    <span class="text-headline">
                      {{ validateString(phone.Fax) }}
                    </span>
                  </div>
                </div>
              </div>
              <dropdown-chevron :is-open="isAttorneyDetailsOpen[i] ?? true" />
            </div>
          </div>
          <div class="bg-disabled p-6" v-if="isAttorneyDetailsOpen[i] ?? true">
            <div class="bg-white shadow-md p-6 rounded-4">
              <div class="grid grid-cols-2 py-4 divide-x">
                <div class="px-6 space-y-2">
                  <div class="flex items-center justify-between">
                    <span class="text-label">
                      {{ $t("COMMON.BUSINESS_ID") }}
                    </span>
                    <span class="text-headline">
                      {{ validateString(attorney.BusinessId) }}
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span class="text-label">
                      {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.UNIQUE_ID") }}
                    </span>
                    <span class="text-headline">
                      {{ validateString(attorney.UniqueId) }}
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span class="text-label">
                      {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.SSN") }}
                    </span>
                    <span class="text-headline">
                      {{ validateString(attorney.SSN) }}
                    </span>
                  </div>
                </div>
                <div class="px-6 space-y-2">
                  <div class="flex items-center justify-between">
                    <span class="text-label">
                      {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.TIMEZONE") }}
                    </span>
                    <span class="text-headline">
                      {{ validateString(attorney.Timezone) }}
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span class="text-label">
                      {{ $t("DEALS.LEXIS_NEXIS.BANKRUPTCIES.APPENDED_SSN") }}
                    </span>
                    <span class="text-headline">
                      {{ validateString(attorney.AppendedSSN) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </toggle-section>
</template>
<script setup lang="ts">
import type { BankruptcyAttorney } from "@/models/commercialData/lexisnexis/BankruptcyReport";
import type { PropType } from "vue";
import { reactive, ref, watch } from "vue";
import ToggleSection from "@/components/ToggleSection.vue";
import { cleanAndFormatAddress } from "@/helpers/lexisnexis";
import { UTIL_COLORS } from "@/helpers/constants";
import { validateString } from "@/helpers/common";

const props = defineProps({
  attorneys: {
    type: Array as PropType<BankruptcyAttorney[]>,
    required: true
  }
});
const isSectionOpen = reactive<Record<string, boolean>>({});
const isAttorneyDetailsOpen = reactive<Record<string, boolean>>({});
const allSectionsOpen = ref(true);

const toggle = (index: string) => {
  index in isSectionOpen
    ? (isSectionOpen[index] = !isSectionOpen[index])
    : (isSectionOpen[index] = false);
};

const handleAttorneyClick = (index: number) => {
  index in isAttorneyDetailsOpen
    ? (isAttorneyDetailsOpen[index] = !isAttorneyDetailsOpen[index])
    : (isAttorneyDetailsOpen[index] = false);
};

watch(allSectionsOpen, (newVal) => {
  if (!props.attorneys) {
    return;
  }

  const attorneysIndices = props.attorneys.map((_, index) => index);
  attorneysIndices.forEach((index) => {
    isAttorneyDetailsOpen[index] = newVal;
  });
});
</script>
